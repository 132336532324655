$margin-min: 1;
$margin-max: 100;

@for $i from $margin-min through $margin-max {
  .my-#{$i} {
    margin-bottom: #{$i}px;
    margin-top: #{$i}px;
  }

  .mx-#{$i} {
    margin-left: #{$i}px;
    margin-right: #{$i}px;
  }

  .mt-#{$i} {
    margin-top: #{$i}px;
  }

  .mr-#{$i} {
    margin-right: #{$i}px;
  }

  .mb-#{$i} {
    margin-bottom: #{$i}px;
  }

  .ml-#{$i} {
    margin-left: #{$i}px;
  }
}

a {
  &.underline {
    text-decoration: underline;
  }

  &.external {
    &::after {
      content: $icon-external-link;
      display: inline-block;
      font-family: $font-icon;
      font-size: 0.8em;
      margin: 0 0.5em;
      text-decoration: none;
    }
  }
}

input {
  &[type='button'],
  &[type='submit'] {
    box-sizing: border-box;
  }

  &[type='text'],
  &[type='password'],
  &[type='email'],
  &[type='number'],
  &[type='tel'],
  &[type='url'],
  &[type='search'] {
    appearance: none;
    border: 1px solid $border-color;
    border-radius: 0;
    padding: 6px;

    @media only screen and (max-width: $breakpoint-s) {
      font-size: 14px;
    }
  }

  &[type='radio'],
  &[type='checkbox'] {
    margin: 3px 0.5ex;

    @media only screen and (max-width: $breakpoint-s) {
      background: #fff;
      border: #ccc 1px solid;
      height: 20px;
      vertical-align: middle;
      width: 20px;
    }
  }
}

textarea {
  appearance: none;
  border: 1px solid $border-color;
  border-radius: 0;
  padding: 6px;

  @media only screen and (max-width: $breakpoint-s) {
    font-size: 14px;
  }
}

optgroup {
  font-style: normal;
  font-weight: bold;
}

select {
  appearance: auto;
  background-color: #fff;
  border: 1px solid $border-color;
  border-radius: 0;
  box-sizing: border-box;
  color: $gray900;
  height: 28px;
  line-height: 14px;
  padding: 2px 2px 2px 4px;

  @media only screen and (max-width: $breakpoint-s) {
    font-size: 14px;
  }

  &::after {
    color: #8e8e8e;
    content: $icon-check;
    font-family: $font-icon;
    font-size: 28px;
    line-height: 34px;
    position: absolute;
    right: 0;
  }
}

img {
  border: none;
}
